@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700&display=swap');

@keyframes blink{
  0% {opacity: 0} 49% {opacity: 0} 50% {opacity: 1}
}

body{ scroll-behavior: smooth; }
body.modal{ overflow: hidden; }

.ti-cursor{
  display: inline;
  position: relative;
  animation: none !important;
}
.ti-cursor:before{
  content: '';
  width: 0.6em;
  height: 0.1em;
  position: absolute;
  bottom: 0.18em;
  transform: translateX(-65%);
  backdrop-filter: invert(1);
  background: #0000000a;
  animation: blink 1s infinite;
  animation-delay: 500ms;
}

.text-verticaly{
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
  
.dark .simplebar-scrollbar:before{
  background: #fff;
}

@layer components{
  .nav-list{
    @apply relative;
  }
  .nav-list:before{
    @apply content-[''] block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all bg-black dark:bg-white w-0 h-0 rounded-full z-[-1];
  }
  .nav-list:hover{
    @apply before:w-[150px] before:h-[150px] text-white dark:text-black;
  }
  .to-top{
    @apply p-8 cursor-pointer relative hover:text-black;
  }
  .to-top:before{
    @apply content-[''] block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-all bg-black dark:bg-white w-0 h-0 rounded-full;
  }
  .to-top:hover{
    @apply before:w-[160px] before:h-[160px] text-white dark:text-black;
  }
}
